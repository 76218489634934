// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-r-vegezemla-js": () => import("./../../../src/pages/r/vegezemla.js" /* webpackChunkName: "component---src-pages-r-vegezemla-js" */),
  "component---src-pages-vyzivovy-poradca-js": () => import("./../../../src/pages/vyzivovy-poradca.js" /* webpackChunkName: "component---src-pages-vyzivovy-poradca-js" */)
}

